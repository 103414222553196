html, body{
  overflow-y: hidden;
  background-color:ivory;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#mint {
  position: fixed;
  /* bottom: 0em; */
  top: 80vh;
  left: 5vw;
  height: 10vh;
  width: 90vw;
  z-index: 25;
  font-family: 'Courier New', Courier, monospace;
  font-size: 2.5em;
  font-weight: 900;
  border-color: black;
  text-shadow: 2px 2px #000;
  justify-content:center;
  /* align-items:center; */
  border-style: outset;
  background-color: blanchedalmond;
  border-radius: 5px;
  color: white;
}

#nav {
  position: fixed;
  top: 70vh;
  left: 5vw;
  /* height: 10vh; */
  width: 90vw;
  z-index: 25;
}

#container {
  position: absolute;
  top: 10vh;
  left: 0;
  height: 90vh;
  /* max-height: 70vh; */
  overflow-y: hidden;
  overflow-x: scroll;
  display: inline-flex;
  width: 100%;
  background-color: transparent;
}

.segment {
  height: 65vh;
  max-height: 300px;
  max-width: 100vw;
}

.caption {
  /* background-color: white; */
  color:lightcoral;
  font-size: 1.25em;
  /* border-color: black; */
  font-weight: bolder;
  text-shadow: 1px 1px 2px red;
  text-align: center;
}

#header {
  position: fixed;
  top: 0;
  right: 0;
  height: 10vh;
  max-height: 200px;
  width: 100%;
  /* background-color: rgba(255, 255, 255, 0.7); */
  background-color:blanchedalmond;
  font-size: 2vh;
  text-align: center;
  margin: 0;
}

h1 {
  position: fixed;
  top: 2.5vh;
  right: 5vw;
  color: #000;
  margin: 0;
  white-space: nowrap;
}
/* 
.buttons {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: -1;
} */

.loading {
  position: absolute;
  top: 10vh;
  left: 10vw;
  font-size: 5em;
  z-index: 1000;
}


.slidecontainer {
  width: 100%; /* Width of the outside container */
}
/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}
/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}
/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04AA6D; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
.output {
  position: fixed;
  left: 5vw;
  top: 65vh;
  z-index: 55;
  font-size: 1.25em;
  font-weight: bolder;
}